import React from "react"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import PageHeader from "components/PageHeader"

import { Content } from "page_components/investments"

import investments from "data/investments"

const Apartments = ({ location }) => {
  const title = "Глівіце"

  return (
    <Layout
      location={location}
      seo={{
        title: "Глівіце - Продаж нових квартир",
        description:
          "Продаж квартир безпосередньо від перевіреного забудовника. Найкращі локації, продумані дизайни та завжди багато зелені.",
      }}
      rmGlobalPopup
    >
      <Breadcrumbs
        title={title}
        parent={{ label: "Квартири", url: "/ua/kvartyry" }}
      />
      <PageHeader title={title} />
      {investments
        ?.filter(item => item.city === "Gliwice")
        .map((item, index) => (
          <Content
            key={index}
            city={item?.city}
            sub_title="Інвестиції в продажі"
            investments={item?.investments}
            office={item?.office}
          />
        ))}
      {/* <Popup type="gliwice" lang="ua" /> */}
    </Layout>
  )
}

export default Apartments
